import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import userService from '../services/userService';
import SideNav from './sidenav';

const Layout = () => {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		if (userService.isLogged()) {
			setIsLoading(false);
		} else {
			userService.logOut();
		}
	}, []);
	return (
		<>
			{!isLoading && (
				<>
					<Header />
					<SideNav />
					<Main className="open">
						<Outlet />
					</Main>
				</>
			)}
		</>
	);
};
export default Layout;

const Main = styled.main`
	background-color: ${props => props.theme.body};
	color: ${props => props.theme.fontColor};
	padding-top: 100px;
	min-height: calc(100vh);
	padding: 100px 5% 50px 5%;
	transition: padding-left 0.3s ease-in-out;
	&.open {
		padding-left: calc(5% + 80px);
	}
`;
