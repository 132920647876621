import userService from '../../services/userService';
const Dashboard = () => {
	const { role } = userService.getUser();
	if (role === 'client') window.location.href = '/members';
	return (
		<>
			{/* {role === 'root' && <RootDash />}
			{role === 'seller' && <SellerDash />}
			{role === 'client' && <ClientDash />} */}
		</>
	);
};

export default Dashboard;
