import userService from './userService';
const onRequest = config => {
	let token = userService.getToken();
	const newconfig = {
		...config,
		headers: {
			...config.headers,
			Authorization: `Bearer ${token}`,
		},
	};
	return newconfig;
};

const onResponse = response => {
	return response.data;
};

const onResponseError = error => {
	if (error.response?.status === 401) {
		userService.logOut();
	}
	return Promise.reject(error);
};

export function setupInterceptorsTo(axiosInstance) {
	axiosInstance.interceptors.request.use(onRequest);
	axiosInstance.interceptors.response.use(onResponse, onResponseError);
	return axiosInstance;
}
