import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import Switch from 'react-switch';
const SwitchBase = props => {
	const { name, label, setFieldValue, disabled, text } = props;
	const handleChange = status => {
		setFieldValue(name, status);
	};
	return (
		<>
			<Field name={name}>
				{({ field, form: { touched, errors }, meta }) => (
					<div>
						<Text>{text}</Text>
						<Wrap checked={field.value}>
							{label && <Label dangerouslySetInnerHTML={{ __html: label }} />}
							<Switch
								onChange={handleChange}
								checked={field.value}
								uncheckedIcon={null}
								width={40}
								height={20}
								handleDiameter={13}
								activeBoxShadow={null}
								disabled={disabled}
								onColor="#00FF00"
							/>
							{meta.touched && meta.error && <MyError>{meta.error}</MyError>}
						</Wrap>
					</div>
				)}
			</Field>
		</>
	);
};
const Wrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: space-between;
	padding: 0.9em 1em;
	border-radius: 5px;
	background: #fff;
	border: 1px solid ${props => props.theme.separatorColor};
	margin-bottom: 2em;
	width: 100%;
	position: relative;
	.react-switch-bg {
		path {
			fill: ${props => props.theme.fontColor};
		}
		${props => {
			console.log(props);
			if (props.checked) {
				return `background-color: #3bcf4d !important;`; // Verde cuando está activado
			} else if (props.theme.name === 'light') {
				return `background-color: ${props.theme.separatorColor} !important;`;
			} else {
				return `
					background-color: transparent !important;
					border: 1px solid ${props.theme.primaryColor} !important;
				`;
			}
		}}
	}
	.react-switch-handle {
		${props => {
			if (props.theme.name === 'light') {
				return `background-color: ${props.theme.background} !important;`;
			} else {
				return `background-color: ${props.theme.primaryColor} !important;`;
			}
		}}
		outline: none!important;
	}
`;
const Label = styled.label`
	display: flex !important;
	align-items: center;
`;
const Text = styled.div`
	padding-left: 5px;
	margin-bottom: 0.5em;
`;
const MyError = styled.div`
	color: red;
	padding: 0.3em;
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 10px;
	z-index: 9996;
`;
export default SwitchBase;
