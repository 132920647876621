import styled from 'styled-components';
import moment from 'moment';
import LoadingSmall from '../../common/LoadingSmall';
import { useCreateSubscription } from '../../../hooks/useCreateSubscription';
import { useIntl } from 'react-intl';
const Policy = props => {
	const { _id, policeName, createdAt } = props;
	const { createSubscription, isLoading } = useCreateSubscription(props._id);
	const { formatMessage } = useIntl();
	return (
		<Wrap>
			<Icon>
				<img
					src={`/images/membership/${policeName.toLowerCase()}.jpg`}
					alt={policeName}
				/>
			</Icon>
			<Name>
				<h3>
					{formatMessage({ id: 'table.membership' })} {policeName}
				</h3>
				<p>{moment(createdAt).format('DD/MM/YYYY')}</p>
			</Name>
			<Pay>
				<button onClick={e => createSubscription(_id)}>
					{isLoading ? <LoadingSmall /> : formatMessage({ id: 'commons.goToPay' })}
				</button>
			</Pay>
		</Wrap>
	);
};
const Wrap = styled.article`
	display: grid;
	grid-template-columns: 70px auto 100px;
	height: 60px;
	background: #fff;
	border-radius: 10px;
	box-shadow:
		0 2px 8px rgba(0, 0, 0, 0.1),
		0 5px 5px rgba(0, 0, 0, 0.12);
	overflow: hidden;
`;
const Icon = styled.div`
	position: relative;
	padding: 5px;
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
const Name = styled.div`
	align-self: center;
	padding: 5px 20px;
	h3 {
		font-size: 1.2rem;
		margin: 0;
	}
`;
const Pay = styled.div`
	align-self: center;
	button {
		font-weight: 700;
		padding: 10px;
		background-color: ${props => props.theme.primaryColor};
		color: #fff;
		border-radius: 50px;
		transition: all 0.2s ease-in;
		border: none;
		cursor: pointer;
	}
`;

export default Policy;
