import { Wrap } from './styles';
import routes from '../../../utils/navigationRoutes';
import UserService from '../../../services/userService';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
const SideNav = () => {
	const user = UserService.getUser();
	const { formatMessage } = useIntl();
	const userRole = user.role;
	return (
		<Wrap>
			{routes.map(route => {
				if (route && route.roles.includes(userRole)) {
					return (
						<li key={route.id}>
							<NavLink
								to={route.url}
								className={({ isActive }) =>
									isActive && window.location.pathname === route.url ? 'active' : ''
								}>
								<i className="material-icons-outlined">{route.icon}</i>
								{formatMessage({ id: route.title })}
							</NavLink>
						</li>
					);
				}
			})}
		</Wrap>
	);
};
export default SideNav;
