import { useContext, useState } from 'react';
import { InsuranceContext } from '../../../context/insurance';
import styled from 'styled-components';
import formatNumber from '../../utils/formatNumber';
import { convertBusinessLine, convertCity } from '../../utils/formUtils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '../../common/Grid';
import Switch from '../../common/forms/Switch';
import Submit from '../../common/forms/Submit';
import { useSearchParams } from 'react-router-dom';
import api from '../../../services/api';
import { useIntl } from 'react-intl';
import { GlobalsContext } from '../../../context/globals';

const Step4 = () => {
	const { state, dispatch } = useContext(InsuranceContext);
	const language = useContext(GlobalsContext);
	let [searchParams] = useSearchParams();
	const { formatMessage } = useIntl();
	return (
		<>
			<Intro>
				<h1>
					<span>{formatMessage({ id: 'police.resume' })}</span>
				</h1>
			</Intro>
			<SectionWrap>
				<SectionTitle>
					<h2>{formatMessage({ id: 'police.contractor' })}</h2>
					<button onClick={e => dispatch({ step: 1 })}>
						<i className="material-icons-outlined">edit</i>
					</button>
				</SectionTitle>
				<Data>
					<p>{formatMessage({ id: 'forms.nameCorporate' })}</p>
					<span>{state.businessName}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'forms.streetAndNumber' })}</p>
					<span>{state.streetAndNumber}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'forms.colony' })}</p>
					<span>{state.colony}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'forms.state' })}</p>
					<span>{state.state}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'forms.companyCity' })}</p>
					<span>{state.companyCity}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'forms.zipCode' })}</p>
					<span>{state.zipCode}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'forms.rfc' })}</p>
					<span>{state.rfc}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'forms.activity' })}</p>
					<span>{formatMessage({ id: `opt.businessLine.${state.businessLine}` })}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'forms.numberOfEmployees' })}</p>
					<span>{state.numberOfEmployees}</span>
				</Data>
			</SectionWrap>
			<SectionWrap>
				<SectionTitle>
					<h2>{formatMessage({ id: 'table.membership' })}</h2>
					<button onClick={e => dispatch({ step: 3 })}>
						<i className="material-icons-outlined">edit</i>
					</button>
				</SectionTitle>
				<Data>
					<p>{formatMessage({ id: 'table.city' })}</p>
					<span>
						<span>{convertCity(state.city)}</span>
					</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'table.membership' })}</p>
					<span>
						<span>{state.policeName}</span>
					</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'forms.compensationLimitLabel' })}</p>
					<span>{`$${formatNumber(state.compensationLimit)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'table.premium' })}</p>
					<span>{`$${formatNumber(state.primaNeta)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'police.expeditionAmount' })}</p>
					<span>{`$${formatNumber(400)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
				</Data>
				<Data>
					<p>{formatMessage({ id: 'police.iva' })}</p>
					<span>{`$${formatNumber(state.IVA)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
				</Data>
				<Data>
					<p>Total</p>
					<span>{`$${formatNumber(state.primaTotal)} ${formatMessage({ id: 'commons.mxn' })}`}</span>
				</Data>
			</SectionWrap>
			<Formik
				initialValues={{
					agree: false,
				}}
				validationSchema={Yup.object({
					agree: Yup.boolean().oneOf([true], 'Debes confirmar que los datos son correctos'),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						let client = searchParams.get('user');
						let data = {
							...state,
							lenguaje: language.state.language,
						};
						if (client) {
							data.client = client;
						}
						let response = await api.post('/police/create', data);
						const link = await api.get(`mercado-pago/create-preference?policeId=${response._id}`);
						window.location.href = link.preference.init_point;
						setSubmitting(false);
					} catch (e) {
						console.log(e);
					}
				}}>
				{formik => (
					<form onSubmit={formik.handleSubmit}>
						<Grid
							columns="1"
							margin="3rem 0 0 0">
							<Switch
								name="agree"
								label={formatMessage({ id: 'msg.termsAndConditions' })}
								setFieldValue={formik.setFieldValue}
							/>
							<Submit disabled={formik.isSubmitting}>
								{formatMessage({ id: 'commons.next' })}
							</Submit>
						</Grid>
						{/* <pre>{JSON.stringify(formik.values, null, 4)}</pre>
						<pre>{JSON.stringify(formik.errors, null, 4)}</pre> */}
					</form>
				)}
			</Formik>
		</>
	);
};
const Intro = styled.div`
	h1 {
		font-size: 2rem;
		font-weight: 700;
	}
	span {
		color: ${({ theme }) => theme.primaryColor};
	}
	p {
		font-size: 1.2rem;
	}
`;
const SectionWrap = styled.div`
	margin-top: 2rem;
`;
const SectionTitle = styled.div`
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	h2 {
		font-size: 1.6rem;
		font-weight: 700;
		margin-bottom: 1rem;
	}
	button {
		background: none;
		border: none;
		color: ${({ theme }) => theme.primaryColor};
		cursor: pointer;
	}
`;
const Data = styled.div`
	margin-bottom: 0.7rem;
	display: flex;
	justify-content: space-between;
	padding-bottom: 0.7rem;
	border-bottom: 1px solid ${({ theme }) => theme.secondaryColor};
	p {
		font-size: 1.1rem;
		color: ${({ theme }) => theme.secondaryColor};
	}
	span {
		font-weight: 700;
		font-size: 1.3em;
		color: ${({ theme }) => theme.primaryColor};
	}
`;

export default Step4;
