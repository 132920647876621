import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '../common/Grid';
import { Intro } from '../common/styles/Intro';
import Input from '../common/forms/Input';
import Switch from '../common/forms/Switch';
import Submit from '../common/forms/Submit';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Select from '../common/forms/Select';
import Password from '../common/forms/Password';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
const RegisterForm = () => {
	let navigate = useNavigate();
	const { formatMessage } = useIntl();
	const [searchParams] = useSearchParams();
	const wilmaId = searchParams.get('wilmaId');
	return (
		<>
			<Intro>
				<h1>
					<span>{formatMessage({ id: 'msg.newUserTitle' })}</span>
				</h1>
				<p>{formatMessage({ id: 'msg.newUser' })}</p>
			</Intro>
			<Formik
				initialValues={{
					name: '',
					lastName: '',
					gender: '',
					age: '',
					telephone: '',
					email: '',
					password: '',
					confirmPassword: '',
					acceptTerms: false,
					seller: wilmaId || '',
				}}
				validationSchema={Yup.object({
					name: Yup.string().required('Campo requerido'),
					lastName: Yup.string().required('Campo requerido'),
					age: Yup.string()
						.matches(/^[0-9]+$/, 'Solo se permiten números')
						.max(3, 'Máximo 3 caracteres')
						.required('Campo requerido'),
					gender: Yup.string().required('Campo requerido'),
					telephone: Yup.string()
						.min(10, 'Mínimo 10 caracteres')
						.max(10, 'Máximo 10 caracteres')
						.required('Campo requerido'),
					email: Yup.string().email('Correo inválido').required('Campo requerido'),
					password: Yup.string().min(6, 'Mínimo 6 caracteres').required('Campo requerido'),
					confirmPassword: Yup.string()
						.oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
						.required('Campo requerido'),
					acceptTerms: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(true);
					values.email = values.email.toLowerCase();
					api
						.post('/auth/register/client', values)
						.then(res => {
							navigate('/registro-exitoso');
						})
						.catch(err => {
							setSubmitting(false);
							if (err.response) {
								toast.error(err.response.data.message);
								if (err.response.data.message === 'El correo ya está registrado') {
									navigate('/login');
								} else if (err.response.data.message === 'El teléfono ya está registrado') {
									navigate('/login');
								}
							} else {
								toast.error('Ocurrió un error, intenta de nuevo');
							}
						});
				}}>
				{formik => (
					<form onSubmit={formik.handleSubmit}>
						<Grid columns="1">
							<Input
								name="name"
								type="text"
								label={formatMessage({ id: 'forms.name' })}
								placeholder={formatMessage({ id: 'forms.nameLabel' })}
							/>
							<Input
								name="lastName"
								type="text"
								label={formatMessage({ id: 'forms.lastName' })}
								placeholder={formatMessage({ id: 'forms.lastNameLabel' })}
							/>
							<Select
								name="gender"
								label={formatMessage({ id: 'forms.gender' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: 'male', label: formatMessage({ id: 'label.male' }) },
									{ value: 'female', label: formatMessage({ id: 'label.female' }) },
									{ value: 'sin-especificar', label: formatMessage({ id: 'label.other' }) },
								]}
							/>
							<Input
								name="age"
								type="text"
								label={formatMessage({ id: 'forms.age' })}
								placeholder={formatMessage({ id: 'forms.ageLabel' })}
							/>
							<Input
								name="telephone"
								type="number"
								label={formatMessage({ id: 'forms.whatsapp' })}
								placeholder={formatMessage({ id: 'forms.whatsappLabel' })}
							/>
							<Input
								name="email"
								type="email"
								label={formatMessage({ id: 'forms.email' })}
								placeholder={formatMessage({ id: 'forms.emailLabel' })}
							/>
							<Password
								name="password"
								type="password"
								label={formatMessage({ id: 'forms.password' })}
								placeholder={formatMessage({ id: 'forms.passwordLabel' })}
							/>
							<Password
								name="confirmPassword"
								type="password"
								label={formatMessage({ id: 'forms.confirmPassword' })}
								placeholder={formatMessage({ id: 'forms.confirmPassword' })}
							/>
							<Input
								name="seller"
								type="text"
								disabled={!!wilmaId}
								label={formatMessage({ id: 'forms.wilmaGuide' })}
								placeholder={formatMessage({ id: 'forms.wilmaGuideLabel' })}
							/>
							<Switch
								name="acceptTerms"
								label={`${formatMessage({ id: 'msg.shortTerms' })}&nbsp;  <a href="https://www.wilma.mx/terminos-y-condiciones" target="_blank">${formatMessage({ id: 'msg.seeTerms' })}</a>`}
								setFieldValue={formik.setFieldValue}
							/>
							<Submit disabled={formik.isSubmitting}>
								{formatMessage({ id: 'forms.register' })}
							</Submit>
						</Grid>
					</form>
				)}
			</Formik>
		</>
	);
};

export default RegisterForm;
