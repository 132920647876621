import Content from 'components/common/Content-v2';
import MainTitle from 'components/common/Title';
import LinkButton from 'components/common/LinkButton';
import AddAllyForm from 'components/forms/allies/add';
const AddMember = props => {
	return (
		<>
			<MainTitle>
				<h1>Añadir aliado</h1>
				<LinkButton to="/admin/allies"> Regresar </LinkButton>
			</MainTitle>
			<Content>
				<AddAllyForm />
			</Content>
		</>
	);
};
export default AddMember;
