import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from '../../common/forms/Input';
import Submit from '../../common/forms/Submit';
import Select from '../../common/forms/Select';
import Grid from '../../common/Grid';
import Password from '../../common/forms/Password';
import userService from '../../../services/userService';
const AddMemberForm = props => {
	let navigate = useNavigate();
	let user = userService.getUser();
	return (
		<Formik
			initialValues={{
				name: '',
				lastName: '',
				gender: '',
				age: '',
				telephone: '',
				email: '',
				password: '',
				confirmPassword: '',
				seller: user.membership,
				acceptTerms: true,
				role: 'client',
				createdByAdmin: true,
				lenguaje: 'es',
			}}
			validationSchema={Yup.object({
				name: Yup.string().required('Campo requerido'),
				lastName: Yup.string().required('Campo requerido'),
				age: Yup.string()
					.matches(/^[0-9]+$/, 'Solo se permiten números')
					.required('Campo requerido'),
				gender: Yup.string().required('Campo requerido'),
				telephone: Yup.string()
					.min(10, 'Mínimo 10 caracteres')
					.max(10, 'Máximo 10 caracteres')
					.required('Campo requerido'),
				email: Yup.string().email('Correo inválido').required('Campo requerido'),
				password: Yup.string().min(6, 'Mínimo 6 caracteres').required('Campo requerido'),
				confirmPassword: Yup.string()
					.oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
					.required('Campo requerido'),
			})}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				api
					.post('auth/register/user', values)
					.then(res => {
						toast.success('Miembro Wilma creado correctamente');
						navigate('/admin/members');
					})
					.catch(err => {
						if (err.response.data.message === 'El correo ya está registrado') {
							toast.error('El correo ya está registrado');
						} else if (err.response.data.message === 'El teléfono ya está registrado') {
							toast.error('El teléfono ya está registrado');
						} else {
							toast.error('Error inesperado');
						}
						setSubmitting(false);
					});
			}}>
			{formik => (
				<>
					<form onSubmit={formik.handleSubmit}>
						<Grid columns="2">
							<Input
								name="name"
								type="text"
								label="Nombre"
								placeholder="Ingresa tu nombre"
							/>
							<Input
								name="lastName"
								type="text"
								label="Apellido"
								placeholder="Ingresa tu apellido"
							/>
							<Select
								name="gender"
								label="Selecciona tu género"
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: 'male', label: 'Masculino' },
									{ value: 'female', label: 'Femenino' },
									{ value: 'sin-especificar', label: 'Prefiero no decirlo' },
								]}
							/>
							<Input
								name="age"
								type="text"
								label="Edad"
								placeholder="Ingresa tu edad"
							/>
							<Input
								name="telephone"
								type="number"
								label="Número de WhatsApp"
								placeholder="Ingresa tu número de WhatsApp"
							/>
							<Input
								name="email"
								type="email"
								label="Correo electrónico"
								placeholder="Ingresa tu correo electrónico"
							/>
							<Password
								name="password"
								type="password"
								label="Contraseña"
								placeholder="Ingresa tu nueva contraseña"
							/>
							<Password
								name="confirmPassword"
								type="password"
								label="Confirma tu contraseña"
								placeholder="Confirma tu contraseña"
							/>
							<Input
								name="seller"
								type="text"
								label="Guía Wilma (opcional)"
								placeholder="Ingresa el código de tu vendedor"
							/>
						</Grid>
						<Submit disabled={formik.isSubmitting}>Guardar</Submit>
					</form>
				</>
			)}
		</Formik>
	);
};

const MyForm = styled.form`
	padding: 1em 0 2em 0;
	width: 100%;
`;

export default AddMemberForm;
