import Title from 'components/common/Title';
import LinkButton from 'components/common/LinkButton';
import { useIntl } from 'react-intl';
import Content from 'components/common/Content-v2';
import AlliesTable from 'components/tables/allies';
const Allies = () => {
	const { formatMessage } = useIntl();
	return (
		<>
			<Title>
				<h1>{formatMessage({ id: 'allies.title' })}</h1>
				<LinkButton to="/admin/allies/add">{formatMessage({ id: 'commons.add' })}</LinkButton>
			</Title>
			<Content>
				<AlliesTable />
			</Content>
		</>
	);
};
export default Allies;
