import { useState } from 'react';
import Title from 'components/common/Title';
import styled from 'styled-components';
import LinkButton from 'components/common/LinkButton';
import { useIntl } from 'react-intl';
import Content from 'components/common/Content-v2';
import QuotesActiveTable from 'components/tables/quotes/activeQuotes';
import QuotesOverdueTable from 'components/tables/quotes/overdueQuotes';
import Calculator from 'components/common/calculator';
const Members = () => {
	const [type, setType] = useState('active');
	const { formatMessage } = useIntl();
	return (
		<>
			<Title>
				<h1>{formatMessage({ id: 'quotes.title' })}</h1>
				<LinkButton to="/admin/quotes/add">{formatMessage({ id: 'commons.add' })}</LinkButton>
			</Title>
			<Calculator />
			<Tabs>
				<Tab
					className={type === 'active' ? 'active' : ''}
					onClick={() => setType('active')}>
					Activas
				</Tab>
				<Tab
					className={type === 'overdue' ? 'active' : ''}
					onClick={() => setType('overdue')}>
					Inactivas
				</Tab>
			</Tabs>
			<Content borderRadius=" 0 .75rem  .75rem .75rem">
				{type === 'active' && <QuotesActiveTable />}
				{type === 'overdue' && <QuotesOverdueTable />}
			</Content>
		</>
	);
};

export const Tabs = styled.div`
	display: flex;
`;
export const Tab = styled.div`
	font-size: 1.2em;
	padding: 0.5em 1em 0.5em 1em;
	border-radius: 5px 5px 0 0;
	background-color: ${props => props.theme.menuLines};
	cursor: pointer;
	&.active {
		background: ${props => props.theme.background};
	}
`;
export default Members;
