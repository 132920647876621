import { useDataTable } from 'hooks/useDataTable';
import StyledTable from 'components/common/StyledTable';
import moment from 'moment';
import Options from './options';
import DownloadReport from 'components/common/DownloadReport';
import { useIntl } from 'react-intl';
const AlliesTable = () => {
	const { isLoading, response, totalDocs, perPage, handlePageChange, handlePerRowsChange } =
		useDataTable('user/search', [{ name: 'role', value: 'partner' }]);
	const { formatMessage } = useIntl();
	const columns = [
		{
			name: formatMessage({ id: 'table.allyId' }),
			selector: row => row.membership,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.createdAt' }),
			selector: row => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.name' }),
			selector: row => row.name,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.email' }),
			selector: row => row.email,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.phone' }),
			selector: row => row.telephone,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.discount' }),
			selector: row => `${row.discount}%`,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.options' }),
			cell: row => <Options id={row.id} />,
			center: true,
			grow: 1,
		},
	];
	return (
		<>
			<StyledTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				pagination
				paginationServer
				paginationTotalRows={totalDocs}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				noDataComponent={<div>No hay datos</div>}
				paginationPerPage={perPage}
			/>
			<DownloadReport
				justify="flex-end"
				endpoint="/user/sellers-csv"
				name={formatMessage({ id: 'commons.downloadAll' })}
			/>
		</>
	);
};
export default AlliesTable;
